<template>
  <div class="header-images-page">
    <h1 class="title">{{$t('Headers')}}</h1>
    <div v-for="(headerInfo,index) in headerFilesInfo" :key="index">
      <b-row class="header-section">
        <b-col class="my-4">
          <h2 class="section-title header">{{headerInfo.headerType}}</h2>
        </b-col>
        <b-col cols="12">
          <div class="header-background my-4 my-lg-1">
            <div class="image" v-bind:class="[ isVideo(headerInfo) ? 'video-container' : '']" v-if="headerInfo.file!=null || headerInfo.pathname!=null" v-bind:style="[!isVideo(headerInfo) ? {backgroundImage: `url(${getPreview(headerInfo)})`,backgroundColor: '#DADBD7', backgroundSize: 'contain'} : {} ]">
              <video v-if="isVideo(headerInfo)" autoplay muted loop playsinline>
                <source :src="getPreview(headerInfo)" type="video/mp4">
              </video>
            </div>
            <RFFile class="my-1" :accepted-files="acceptedFiles" size="small" label="Add file" background="#fff" color="#5CB86C" border-color="#5CB86C"
                    v-if="!(headerInfo.file!=null || headerInfo.pathname!=null)" @file="uploadFile($event,headerInfo)"/>
            <div class="hover"  v-if="headerInfo.file!=null || headerInfo.pathname!=null">
              <FontAwesomeIcon class="delete-icon" color="#fff" size="lg" :icon="['fas', 'trash']"
                               @click="deleteHeaderFile(headerInfo)"/>
            </div>
          </div>
        </b-col>
        <b-col class="my-4">
          <p class="admin-aclaration">{{$t(`Images and videos max size is ${fileSizeLimit} MB (Max size recommended: ${fileSizeLimitRecommended} MB)`)}}</p>
        </b-col>
      </b-row>
    </div>
    <b-row class="justify-content-end">
      <b-col cols="auto">
        <RFButton class="mt-4" label="Save" background="#5CB85C" v-if="!loading" @on-click="save"/>
        <RFLoader :size="30" color="#24425B" v-else/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFFile from '@/components/forms/RFFile'
import RFLoader from '@/components/forms/RFLoader'
import {headerService} from '@/services/header.service';
import {getAsset} from '@/helpers';

export default {
  name: 'Headers',
  components: {
    RFButton,
    RFFile,
    RFLoader,
  },
  data() {
    return {
      file: null,
      imagePath: null,
      loading: false,
      acceptedFiles:['jpeg', 'jpg', 'png', 'mp4'],
      headerFilesInfo:[],
      headerTypes : [],
      defaultHeaderFilesInfo:{
        APARTMENTS: {
          pathname: '/images/apartment_rf.jpg',
          type: 'image/jpg'
        },
        BUILDINGS: {
          pathname: '/images/building_page_header.jpg',
          type: 'image/jpg'
        },
        LANDING: {
          pathname: '/images/home_header.jpg',
          type: 'image/jpg'
        }
      },
      fileSizeLimit: 100, //In MB
      fileSizeLimitRecommended: 10, //In MB
    }
  },
  async beforeMount() {
    await this.getHeaderImages()
  },
  methods: {
    getPreview(headerFileInfo) {
      return headerFileInfo.file ?  URL.createObjectURL(headerFileInfo.file) : getAsset(headerFileInfo.pathname)
    },
    uploadFile(event,headerInfo){
      if(event.size > this.fileSizeLimit * 1024 * 1024){
        this.$toasted.error(this.$i18n.t(`You have uploaded a file with size not allowed (must be lower or equals than ${this.fileSizeLimit} MB)`))
      }
      else{
        headerInfo.file = event
        headerInfo.modified = true
        headerInfo.pathname = URL.createObjectURL(headerInfo.file)
      }
    },
    deleteAmenityImage() {
      this.imagePath = null
      this.file = null
    },
    deleteHeaderFile(headerInfo){
      headerInfo.pathname=null
      headerInfo.file = null
      headerInfo.modified = true
    },
    isVideo(headerFileInfo){
      return (headerFileInfo.pathname!=null  && headerFileInfo.pathname.split('.')[1] === 'mp4') || (headerFileInfo.file!=null  && headerFileInfo.file.type.split('/')[1] === 'mp4')
    },
    async save() {

      this.loading = true

      await Promise.all(
        this.headerFilesInfo.map(async (headerFileInfo,index)=>{
          if(headerFileInfo.modified){
            if(headerFileInfo.file==null||headerFileInfo.pathname==null){
              let extension = headerFileInfo.defaultType.split('/')[1]
              let blob = await fetch(headerFileInfo.defaultPathname).then(r => r.blob());
              headerFileInfo.file =  new File([blob], headerFileInfo.headerType+"."+extension, {
                type: headerFileInfo.defaultType,
              })
            }
            const form = new FormData()
            form.append('headerType', headerFileInfo.headerType)
            form.append('file', headerFileInfo.file)

            const {status} = await headerService.putImage(form)
            if(status === 200){
              headerFileInfo.modified = false
              this.$toasted.success(this.$i18n.t(`Header files correctly set!`))
            }
          }
        })
      )
        this.loading = false
      },
    async getHeaderImages(){
      const headerFiles = await headerService.getImages()
      if(headerFiles!==undefined && headerFiles.length!==0){
        headerFiles.forEach(headerFile => {
          let headerFileInfo = {
            pathname: headerFile.pathname,
            file: null,
            modified: false,
            headerType: headerFile.headerType,
            defaultPathname: this.defaultHeaderFilesInfo[headerFile.headerType].pathname,
            defaultType: this.defaultHeaderFilesInfo[headerFile.headerType].type
          }
          this.headerFilesInfo.push(headerFileInfo)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.video-container {
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.header-background {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  max-height: 250px;
  border: 1px solid $gray;
  cursor: pointer;

  &:hover {
    .hover {
      opacity: 1;
    }
  }

  .hover {
    position: absolute;
    background-color: rgba($black, .4);
    width: 100%;
    height: 100%;
    z-index: 30;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .3s;

    .delete-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .trash-icon {
    position: absolute;
    bottom: -5px;
    right: -5px;
  }
}
.header-images-page {
  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
    text-transform: uppercase;
  }
  .header-section {
    margin-top: 40px;

    .header {
      border-bottom: 1px solid $dark-gray;
    }
    .section-title {
      @include font-style($montserrat, 'semibold', $font-22);
      color: $dark-blue;
      text-transform: uppercase;
      margin: 0;
    }
    .add-info-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $success;
    }
  }
}
</style>
